<template>
    


<div class="container">
                    <div class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid" id="kt_subheader">
                            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                <!--begin::Info-->
                                    <div class="d-flex align-items-center flex-wrap mr-2">
                                        <div class="text-lg-right">
                                        </div>
                                    </div>

                                <div class="d-flex align-items-center flex-wrap mr-2">
                                    <!--begin::Page Title-->
                                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Admin Add Supplier / Brand</h5>
                                    <!--end::Page Title-->
                                    <!--begin::Action-->
                                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>

                                    <span class="text-muted font-weight-bold mr-4"> </span>
                                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
                                    <!--end::Action-->
                                </div>
                                <!--end::Info-->
                            </div>
                    </div>
 

        <div class="row">
            <div class="col-lg-12">

                <v-card-title class="headline  lighten-3">
<strong>    <span>Create a New Partner  </span></strong>  </v-card-title>


              <h5>Search to see if Existing Partner Exists </h5>  


    

            <v-autocomplete
        v-model="company"
        :items="companies"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="company_name"
        item-value="id"
        placeholder="Start typing to Search"
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>



<b-form class="form" @submit.stop.prevent="createCompany">
 <v-card class="h-100"
    light
  >
    <v-card-title class="headline  lighten-3">
New Seller Partner Info     </v-card-title>
    <v-card-text>

<div class="row">

  <div class="col-lg-6">
     


      
                            <div class="form-group mt-4 row">
                                <label  class="col-3  col-form-label text-dark">Contact Email </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.email.$model"
                                      :state="validateState('email')"
                                      aria-describedby="input-1-live-feedback"
                                      placeholder=""
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="input-1-live-feedback">
                                      Email is required and a valid email.
                                    </b-form-invalid-feedback>
                                </div>
                            </div>


                             <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Company Name </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.company_name.$model"
                                      :state="validateState('company_name')"
                                      aria-describedby="input-2-live-feedback"
                                      placeholder=""
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="input-2-live-feedback">
                                      Company name is required.
                                    </b-form-invalid-feedback>
                                </div>
                            </div>

                                 <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Website </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.website.$model"
                                      :state="validateState('website')"
                                      aria-describedby="input-2-live-feedback"
                                      placeholder=""
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="input-2-live-feedback">
                                      website is required.
                                    </b-form-invalid-feedback>
                                </div>
                            </div>
                                   <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Phone </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.phone.$model"
                                      aria-describedby="input-2-live-feedback"
                                      placeholder=""
                                    ></b-form-input>

                              
                                </div>
                            </div>


   


                                     <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Number of Employees </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.employee.$model"
                                      
                                      placeholder=""
                                    ></b-form-input>

                               
                                </div>
                            </div> 


                                       <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Year Founded </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.founded.$model"
                                      placeholder=""
                                    ></b-form-input>

                               
                                </div>
                            </div>
         

  </div>
   <div class="col-lg-6">
                                  <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Facebook URL </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.facebook.$model"
                                      placeholder=""
                                    ></b-form-input>

                               
                                </div>
                            </div>
                                      <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Instagram URL</label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.instagram.$model"
                                      placeholder=""
                                    ></b-form-input>

                               
                                </div>
                            </div>
                                                 <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">City</label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.city.$model"
                                      placeholder=""
                                    ></b-form-input>

                               
                                </div>
                            </div>
                                                 <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">State 2 Letter</label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.state.$model"
                                      placeholder=""
                                    ></b-form-input>

                               
                                </div>
                            </div>
                                                <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Description</label>
                                <div class="col-9">

                                      <b-form-textarea
      id="textarea"
      v-model="$v.description.$model"
      placeholder="Enter something..."
      rows="3"
      max-rows="6"
    ></b-form-textarea>


                               
                                </div>
                            </div>


  </div>


</div>




           


















                                 
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="grey darken-3"
        type="submit"
      >
        Create
        <v-icon right>mdi-account-circle</v-icon>
      </v-btn>
    </v-card-actions>
    
  </v-card>
</b-form>


            </div>

            <div class="col-lg-6">
      






            </div>

        </div>


 <div class="row">
            <div class="col-lg-6">


      
            </div>

            <div class="col-lg-6">
            </div>
 </div>
            












</div>





</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import gql from "graphql-tag";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      company: null,
      search: "",
      companies: [],
      typeItems: [
        
        'supplier'
      ],
      type: "supplier",
      email: "",
      website: "",
      city: "",
      state: "",
      phone: "",
      employee: "",
      facebook: "",
      instagram: "",
      founded: "",
      company_name: ""
    };
  },
  validations: {
		email: {
			required,
			email
    },
            employee: {
      required
    },
               founded: {
      required
    },
               facebook: {
      
    },
               instagram: {
      
    },
          phone: {
      
    },
       website: {
      required
    },
          description: {
      required
    },
          city: {
      required
    },
           state: {
      required
    },
    company_name: {
      required
    }
  },
  apollo: {
    companies: {
      query: gql`
        query Companies($search: String) {
          companies(
            where: {
              company_name_contains: $search
            }) {
            id
            company_name
          }
        }
      `,
      variables() {
        return {
          search: this.search == null ? '' : this.search
        }
      },
    }
  },
  methods: {
    ...mapActions([
        'submitCompany',
        'getCompanyTotalCount',
        'submitBusinessProfile',
        'submitProfile'
    ]),
    validateState(name) {
			const { $dirty, $error } = this.$v[name];
			return $dirty ? !$error : null;
		},
    createCompany(e) {
      e.preventDefault();
			this.$v.$touch();
			if (this.$v.$anyError) {
				return;
      }
      
      this.getCompanyTotalCount({
        params: "company_name="+ this.company_name
      })
      .then((res) => {
        if(res.data == 0) {
          if(this.type == 'buyer') {
            this.submitBusinessProfile({
              company_name: this.company_name
            })
            .then((resp) => {
              this.submitCompany({
                company_name: this.company_name,
                website: this.website,
                email: this.email,
                type: this.type,
                business_profile: resp.data.id
              })
              .then(() => {
                Swal.fire({
                  title: "",
                  text: "The company has been successfully created!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary"
                });
                location.reload();
              });
            });
          } else {
            this.submitProfile({
              Name: this.company_name,
              profile_website: this.website,
            profile_contact_email: this.email,
            profile_company_name: this.company_name,
             profile_phone: this.phone,
               profile_founded: this.founded,
               profile_employee: this.employee,
               facebook: this.facebook,
               instagram: this.instagram,
               profile_city: this.city,
                profile_state: this.state,
               profile_long_description: this.description



            })
            .then((resp) => {
              this.submitCompany({
                company_name: this.company_name,
                email: this.email,
                website: this.website,
                type: this.type,
                profile: resp.data.id
              })
              .then(() => {
                Swal.fire({
                  title: "",
                  text: "The company has been successfully created!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary"
                });
                location.reload();
              });
            });
          }
        }
        else {
          this.$bvToast.toast('Company with the same name already exists!', {
            title: 'An Error Occured!',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: true
          });
        }
      })
      .catch(() => {
        this.isConnecting = false;
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true
        });
      })
    }
  }
};



</script>


